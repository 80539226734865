const initialState = { deposit: [{ idx: null, price: null, quantity: null, total: null, time: null }] };

export const loadRecord = (data) => {
	return {
		type: "LOAD",
		data,
	};
};

export const addRecord = (data) => {
	return {
		type: "ADD_RECORD",
		data,
	};
};

const recordReducer = (state = initialState, action) => {
	switch (action.type) {
		case "LOAD":
			return {
				...state,
				deposit: action.data,
			};

		default:
			return state;
	}
};

export default recordReducer;
