import React from 'react';
 import { Navigate } from 'react-router-dom';

 function PublicRoute({ component: Component }) {

    const authenticated = localStorage.getItem("isLoggedIn")
   return (
     authenticated ? <Navigate to='/main' /> : Component
   )
 }

 export default PublicRoute 