import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage";
import userReducer from "./modules/user";
import recordReducer from "./modules/record";

const persistConfig = {
	key: "root", // sessionStorage key
	storage: sessionStorage, // sessionStorage
};

export const rootReducer = combineReducers({
	userReducer,
	recordReducer,
});

export default persistReducer(persistConfig, rootReducer);
