import React from 'react';
 import { Navigate } from 'react-router-dom';

 function PrivateRoute({ component: Component }) {

    const authenticated = localStorage.getItem("isLoggedIn")
   return (
     authenticated ? Component : <Navigate to='/' />
   )
 }

 export default PrivateRoute 