import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";
const Main = lazy(() => import("./components/Main"));
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./components/Login";
import { Provider, useSelector } from "react-redux";
import store from "./components/store/store";
import { PersistGate } from "redux-persist/integration/react";

import { persistStore } from "redux-persist";

const Password = lazy(() => import("./components/Mypage/Password"));
const Delete = lazy(() => import("./components/Mypage/Delete"));
import { Spin } from "antd";
import styled from "styled-components";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";

const Background = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: #ffffffb7;
	z-index: 999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.content {
		width: 100px;
	}
`;

const persistor = persistStore(store);

function App() {
	const isLoggedIn = localStorage.getItem("isLoggedIn");

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor} loading={null}></PersistGate>
			<BrowserRouter>
				<Header />

				<Suspense
					fallback={
						<Background>
							<Spin size="large" tip="로딩중">
								<div className="content" />
							</Spin>
						</Background>
					}
				>
					<Routes>
						<Route exact path="/main" element={<PrivateRoute component={<Main />} />}></Route>

						<Route exact path="/password" element={<PrivateRoute component={<Password />} />}></Route>

						<Route exact path="/delete" element={<PrivateRoute component={<Delete />} />}></Route>

						<Route exact path="/" element={<PublicRoute component={<Login />} />}>
							<Route exact path="/register" element={<PublicRoute component={<Login />} />}></Route>
						</Route>

						{/* 404 리다이렉트 처리 */}
						<Route path="/*" element={<Navigate to="/"></Navigate>}></Route>
					</Routes>

					<Footer />
				</Suspense>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
