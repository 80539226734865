import React, { useState, useEffect } from "react";
import { Menu, Button } from "antd";
import styled from "styled-components";
import { MenuOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import store from "../store/store";
import axios from "axios";

const MenuList = styled.div`
	display: flex;
	justify-content: space-between;
`;

const NavTop = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 5px;
	button {
		color: #fff;
		border: none;
		box-shadow: none;
	}
`;

const NavWrapper = styled.div`
	a {
		text-decoration: none;
	}
`;

const Navbar = () => {
	const [toggleMenu, setToggleMenu] = useState(false);
	const [toggleBar, setToggleBar] = useState(true);
	const navigate = useNavigate();

	const toggleChange = () => {
		setToggleMenu(!toggleMenu);
		setToggleBar(!toggleBar);
	};

	const onMenuClick = () => {
		setToggleMenu(!toggleMenu);
		setToggleBar(!toggleBar);
	};

	const logout = () => {
		// store.dispatch({
		// 	type: "LOG_OUT",
		// });
		// navigate("/");

		axios.post("/api/logout.php").then((res) => {
			console.log(res.data);
			store.dispatch({
				type: "LOG_OUT",
				users: res.data.userinfo,
			});
			localStorage.clear()
			navigate("/");
		});
	};

	return (
		<NavWrapper>
			<NavTop>
				<Button type="" onClick={toggleChange}>
					{toggleBar ? <MenuOutlined /> : <MenuFoldOutlined />}
				</Button>
			</NavTop>
			{toggleMenu && (
				<Menu defaultSelectedKeys={["1"]} mode="inline" theme="light" inlineCollapsed={toggleBar} onClick={onMenuClick}>
					<Menu.Item key="subs">
						<Link to="/main">내 지갑</Link>
					</Menu.Item>
					<hr />
					<Menu.Item key="signup">
						<a onClick={logout}>로그아웃</a>
					</Menu.Item>
				</Menu>
			)}
		</NavWrapper>
	);
};

export default Navbar;
