import logo from "../img/logo.png";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Mobile, PC } from "./ResponsiveController";
import Navbar from "./Dropdown/Navbar";
import store from "./store/store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import back from "../img/border-back.png";


const HeadWrapper = styled.header`
	height: 70px;
	padding: 10px 10px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	position: fixed;
	top: 0;

	img {
		height: 50px;
	}

	.title {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
`;

const LogoWrapper = styled.div`
	display: flex;

	a,
	div {
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: black;
	}

	.abtn {
		width: 100px;
	}

	ul {
		width: 100px;
		cursor: pointer;
		text-align: center;
		list-style: none;
		display: inline-block;
		padding-left: 0;

		.DropdownWrapper {
			padding: 4px;
			border-radius: 6px;
			background-color: white;
			box-shadow: 0 2px 12px 0 rgba(37, 42, 49, 0.08), 0 2px 5px 0 rgba(37, 42, 49, 0.15);
			display: inline-block;
		}

		li {
			line-height: 30px;
			cursor: pointer;
			list-style: none;
			width: 100px;
		}
	}

	.log_hover:hover {
		transition: all 0.5s ease;
		color: #00ff00;
	}
`;

const BtnWrapper = styled.div`
	align-items: center;
	line-height: 50px;
	width: 226.88px;
	display: flex;
	justify-content: flex-end;
	button {
		font-size: 14px;
		color: #fff;
		font-family: "Apple SD Gothic Neo" !important;
		font-weight: 900;
	}
`;


const Header = ({ children }) => {
	const navigate = useNavigate();
	
	const isLoggedIn = useSelector((state) => state.userReducer.user.isLoggedIn);
	
	const logout = () => {
		axios.post("/api/logout.php").then((res) => {
			store.dispatch({
				type: "LOG_OUT",
				users: res.data.userinfo,
			});
			localStorage.clear()
			navigate("/");
		});
	};

	useEffect(() => {
		if(!isLoggedIn) {
			logout();
		}
	}, [])

	return (
		<>
			{isLoggedIn ? (
				<>
				<HeadWrapper className="">
					<LogoWrapper>
						<Link to="/">
							<img src={logo} />
						</Link>
					</LogoWrapper>
					<PC>
						<div className="title">내 지갑</div>
					</PC>
					<PC>
						<BtnWrapper>
							<button className="btn text-whie" onClick={logout}>
								로그아웃
							</button>
						</BtnWrapper>
					</PC>
					<Mobile>
						<Navbar></Navbar>
					</Mobile>
				</HeadWrapper>
				<PC>
				<div className="pc_background" style={{ backgroundImage: `url(${back})`}}/>
				</PC>
				<Mobile>
				<div className="mobile_background" style={{ backgroundImage: `url(${back})`}}/>
				</Mobile>
				</>
			) : (
				""
			)}
		</>
	);
};

export default Header;
