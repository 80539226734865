
const initialState = {
    user: {
        isLoggedIn: false,
        userID: null,
        userinfo: {
        }
    }
}


export const reloadData = (data, user) => {
    return {
        type: 'RELOAD',
        data,
        user
    }
}


export const loginAction = (data, user) => {
    return {
        type: 'LOG_IN',
        data,
        user
    }
}

export const logoutAction = () => {
    return {
        type: 'LOG_OUT'
    }
}


export const testAction = () => {
    return {
        type: 'data'
    }
}



const userReducer = ((state = initialState, action) => {
    switch (action.type) {
        case 'LOG_IN':
            return {
                ...state,
                user: {
                    ...state.user,
                    isLoggedIn: true,
                    userID: action.data,
                    userinfo: action.users
                }
            }
        case 'RELOAD':
            return {
                ...state,
                user: {
                    ...state.user,
                    isLoggedIn: true,
                    userID: action.data,
                    userinfo: action.users
                }
            }
        case 'LOG_OUT':
            return {
                ...state,
                user: {
                    ...state.user,
                    isLoggedIn: false,
                    userID: null,
                    userinfo: {}
                }
            }
        default:
            return state;
    }
})

export default userReducer