import { useState, useCallback, useEffect, lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { Form, Alert, Select, Input, Button } from "antd";
import store from "./store/store";
import bg01 from "../img/bg-core.png";
import logo from "../img/logo.png";
const Register = lazy(() => import("./Register"));
import LoginFile from "./LoginFile";
import { useSelector } from "react-redux";
import { devices } from "./Constants/Device";

const LoginForm = styled.div`
	width: 100%;

	.h-screen {
		display: flex;
		justify-content: center;
		height: 100vh;
		background-image: url(${bg01});
		background-size: cover;

		.container {
			display: flex;
			align-items: center;
			justify-content: center;

            .card {
                padding:80px 40px;
            }
		}

		img {
			width: 350px;
			padding: 30px;
		}

		@media ${devices.sm} {
			img {
				width: 100%;
				padding: 10px;
			}
		}
	}

	.navWrapper {
		border-bottom: 2px dashed #eceff5;
		display: flex;
		margin-bottom: 25px;

		& > a {
			text-decoration: none;
			color: #ffffff;
			padding: 10px;
			display: block;
			position: relative;
			top: 3px;
		}

		& > a.active {
			color: mediumpurple;
			border-bottom: 3px solid mediumpurple;
		}

        & > a:hover {
            color: mediumpurple;
        }
	}

	.card-footer {
		font-size: 12px;
		text-align: center;
		font-family: "Apple SD Gothic Neo" !important;
	}
`;

const Login = () => {
	const isLoggedIn = useSelector((state) => state.userReducer.user.isLoggedIn);
	const navigate = useNavigate();

	useEffect(() => {
		if (isLoggedIn) {
			navigate("/main");
		}
	}, []);

	return (
		<>
			<LoginForm>
				<section className="h-screen">
					<div className="container">
						<div className="card bg-dark">
							<img src={logo} className="card-img-top" alt="..." />
							<div className="card-body">
								<div className="navWrapper">
									<NavLink to="/">로그인</NavLink>
									<NavLink to="/register">회원가입</NavLink>
								</div>

								<Routes>
									<Route path="/" element={<LoginFile />} />
									<Route path="/register" element={<Register />} />
								</Routes>
							</div>
						</div>
					</div>
				</section>
			</LoginForm>
		</>
	);
};

export default Login;
